import React, { useState } from "react";
import CarecrewLogo from "../assets/carecrew";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="w-full bg-white shadow-md sticky top-0 z-50">
      <div className="flex items-center justify-between px-5 sm:px-10 lg:px-20 py-4">
        {/* Logo Section */}
        <div className="flex items-center">
          <CarecrewLogo size={13} />
        </div>

        {/* Desktop Navigation */}
        {/* <nav className="hidden md:flex gap-8">
          <a
            href="#"
            className="text-gray-600 hover:text-blue-600 transition duration-300"
          >
            Home
          </a>
          <a
            href="#"
            className="text-gray-600 hover:text-blue-600 transition duration-300"
          >
            Features
          </a>
          <a
            href="#"
            className="text-gray-600 hover:text-blue-600 transition duration-300"
          >
            Pricing
          </a>
          <a
            href="#"
            className="text-gray-600 hover:text-blue-600 transition duration-300"
          >
            Contact
          </a>
        </nav> */}

        {/* Sign-Up Button */}
        <a
          href="https://app.carecrew.co/"
          target="_blank"
          rel="noopener noreferrer"
          className="hidden md:inline-block bg-PRIMARY_COLOR text-white px-6 py-2 rounded-full text-sm hover:bg-SECONDARY_COLOR transition duration-300"
        >
          Go To Dashboard
        </a>

        {/* Mobile Menu Button */}
        <button
          onClick={toggleMenu}
          className="md:hidden text-gray-600 hover:text-blue-600 focus:outline-none"
          aria-label="Toggle Menu"
          aria-expanded={isMenuOpen}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
            />
          </svg>
        </button>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <nav
          className="flex flex-col items-center bg-white shadow-md md:hidden"
          aria-label="Mobile Menu"
        >
          <a
            href="#"
            className="block py-3 text-gray-600 hover:text-blue-600 transition duration-300"
          >
            Home
          </a>
          <a
            href="#"
            className="block py-3 text-gray-600 hover:text-blue-600 transition duration-300"
          >
            Features
          </a>
          <a
            href="#"
            className="block py-3 text-gray-600 hover:text-blue-600 transition duration-300"
          >
            Pricing
          </a>
          <a
            href="#"
            className="block py-3 text-gray-600 hover:text-blue-600 transition duration-300"
          >
            Contact
          </a>
          <a
            href="https://app.carecrew.co/"
            target="_blank"
            rel="noopener noreferrer"
            className="block py-3 bg-blue-600 text-white w-11/12 text-center rounded-md hover:bg-blue-700 transition duration-300"
          >
            Go To Dashboard
          </a>
        </nav>
      )}
    </header>
  );
};

export default Header;
