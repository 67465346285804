import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const features = [
  {
    icon: "/assets/clock.svg",
    title: "Clock-in Clock-out",
    description: "Real Time Tracking with Geofencing Technology",
    dark: true,
  },
  {
    icon: "/assets/clipboard-check.svg",
    title: "Task Management",
    description: "Real Time task management and reporting",
    dark: false,
  },
  {
    icon: "/assets/calendar-date.svg",
    title: "Scheduling",
    description:
      "Advance task scheduling from job creation to payroll download",
    dark: true,
  },
  {
    icon: "/assets/users-right.svg",
    title: "Employee Availability Tracker",
    description: "Granular hour-based employee availability and filtration.",
    dark: false,
  },
  {
    icon: "/assets/message-smile-circle.svg",
    title: "Work Chat Feature",
    description: "Employees, Hospitals, and Staffing companies can chat In-App",
    dark: true,
  },
];

const FeaturesSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <section className="py-16 px-4 bg-white text-center">
      <h2 className="text-2xl lg:text-3xl font-bold text-gray-800 mb-6">
        Revolutionizing nurse staffing with tailor-made ERP features
      </h2>
      <p className="text-lg lg:text-xl text-gray-600 mb-10 max-w-2xl mx-auto">
        Powerful, self-serve product and growth analytics to help you convert,
        engage, and retain more users. Trusted by over 4,000 startups.
      </p>
      <Slider {...settings}>
        {features.map((feature, index) => (
          <div key={index} className="px-2">
            <div
              className={`p-6 rounded-lg shadow-lg text-center ${
                feature.dark
                  ? "bg-gray-900 text-white"
                  : "bg-gray-100 text-gray-800"
              }`}
            >
              <div className="flex justify-center items-center mb-4">
                <img
                  src={feature.icon}
                  alt={feature.title}
                  className="h-12 w-12"
                />
              </div>
              <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
              <p className="text-base">{feature.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default FeaturesSection;
