import React from "react";
import themeStyles from "../styles/theme.styles";

const GetSection = () => {
  return (
    <section
      className="py-16 text-white rounded-xl"
      style={{ backgroundColor: themeStyles?.PRIMARY_COLOR }}
    >
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between px-6 lg:px-10">
        <div className="lg:w-1/2 mb-10 lg:mb-0 text-center lg:text-left">
          <h2 className="text-4xl font-bold mb-4">Get ShiftMedix Now!</h2>
          <p className="text-lg mb-6">
            Personal performance tracking made easy.
          </p>
          {/* <div className="flex justify-center lg:justify-start space-x-4">
            <a href="#">
              <img
                src="/assets/apple_download.png"
                alt="App Store"
                className="h-12"
              />
            </a>
            <a href="#">
              <img
                src="/assets/play_download.png"
                alt="Google Play"
                className="h-12"
              />
            </a>
          </div> */}
        </div>
        <div className="flex justify-center lg:w-1/2">
          <img
            src="/assets/get_carecrew_now_content.png"
            alt="ShiftMedix App"
            className="w-full max-w-xs lg:max-w-md"
          />
        </div>
      </div>
    </section>
  );
};

export default GetSection;
