import React from "react";

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="flex items-start p-4 bg-white rounded-lg shadow-md mb-6">
      <div className="text-2xl mr-4 rounded-lg p-2 border-gray-200 border-[1px] w-1/6">
        <img src={icon} alt={title} className="h-full w-full" />
      </div>
      <div>
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-600 mb-2">{description}</p>
        <a href="#" className="text-blue-600 font-semibold">
          Learn more →
        </a>
      </div>
    </div>
  );
};

const UpcomingSection = () => {
  const features = [
    {
      icon: "/assets/message-chat-circle.svg",
      title: "Advance AI-Based Reporting Tool",
      description:
        "Unlock the power of data with our AI-driven reporting tool. Gain deep insights into staffing patterns, resource utilization, and overall performance with advanced analytics designed specifically for healthcare. Make informed decisions with real-time data, predictive analysis, and customized reports tailored to your facility’s needs.",
    },
    {
      icon: "/assets/zap.svg",
      title: "Virtual Assistant AI Tool",
      description:
        "Enhance your team’s efficiency with our Virtual Assistant AI. From managing routine tasks to providing real-time support, this tool helps your staff stay focused on delivering exceptional patient care. The Virtual Assistant AI handles everything from appointment scheduling to answering common queries, allowing your healthcare professionals to concentrate on what matters most.",
    },
  ];

  return (
    <section className="flex flex-col lg:flex-row items-center py-16 bg-gradient-to-r from-white to-blue-50">
      <div className="container mx-auto px-6 lg:w-1/2">
        <div className="text-left mb-12">
          <div className="inline-block bg-blue-100 text-PRIMARY_COLOR px-3 py-1 rounded-full text-sm mb-4">
            UPCOMING
          </div>
          <h2 className="text-4xl font-semibold text-black mb-2">
            Future with{" "}
          </h2>
          <h2 className="text-PRIMARY_COLOR text-5xl font-bold mb-4">
            Artificial Intelligence
          </h2>
          <p className="text-lg text-gray-700 mb-10">
            Spend smarter, lower your bills, get cashback on everything you buy,
            and unlock credit to grow your business.
          </p>
        </div>
        <div className="grid gap-8">
          {features.map((feature, index) => (
            <FeatureCard
              key={index}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>
      </div>
      <div className="lg:w-1/2 mt-8 lg:mt-0 flex justify-center">
        <img
          src="/assets/ai_section_img.png"
          alt="Artificial Intelligence"
          className="w-full max-w-md lg:max-w-full lg:h-[90%] object-cover"
        />
      </div>
    </section>
  );
};

export default UpcomingSection;
