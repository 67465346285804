import React from "react";

const VideoSection = () => (
  <section className="px-4 sm:px-8 lg:px-16 py-12 bg-white text-center">
    <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-4">
      Discover the Power of CrewCare: A Visual Tour
    </h2>
    <p className="text-base sm:text-lg text-gray-600 mb-8">
      Uncover the Features, Benefits, and Success Stories
    </p>
    <div className="flex justify-center items-center bg-[#0a2540] p-4 sm:p-8 rounded-lg">
      <div className="w-full max-w-lg relative aspect-w-16 aspect-h-9 bg-white rounded-lg overflow-hidden">
        <iframe
          className="absolute top-0 left-0 w-full h-full"
          src="https://www.youtube.com/embed/dQw4w9WgXcQ" // Placeholder video URL
          title="Video tour"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  </section>
);

export default VideoSection;
