import React, { useState } from "react";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import FeaturesSection from "../components/FeaturesSection";
import PricingSection from "../components/PricingSection";
import TestimonialsSection from "../components/TestimonialsSection";
import FAQSection from "../components/FAQSection";
import Footer from "../components/Footer";
import VideoSection from "../components/VideoSection";
import UpcomingSection from "../components/UpcomingSection";
import ShiftManagementSection from "../components/ShiftManagementSection";
import GetSection from "../components/GetSection";

const LandingPage = () => {
  const [isModalVisible, setModalVisible] = useState(false);

  const handleOpenModal = () => setModalVisible(true);
  const handleCloseModal = () => setModalVisible(false);

  return (
    <div className="flex flex-col items-center bg-gray-100">
      <Header />
      <div className="container mx-auto w-full max-w-screen-xl px-4 sm:px-6 md:px-10 lg:px-20">
        <HeroSection
          isVisible={isModalVisible}
          onClose={handleCloseModal}
          onOpen={handleOpenModal}
        />
        <VideoSection />
        <FeaturesSection />
        <UpcomingSection />
        <ShiftManagementSection />
        {/* <PricingSection /> */}
        <TestimonialsSection />
        <GetSection />
        <FAQSection />
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
