import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CarecrewLogo from "../assets/carecrew";

const Footer = () => {
  return (
    <footer className="bg-gray-100 py-8">
      <div className="container mx-auto px-6 lg:px-20">
        {/* Logo and Links Section */}
        <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start text-center lg:text-left">
          {/* Logo Section */}
          <div className="mb-8 lg:mb-0">
            <CarecrewLogo size={11} />
          </div>

          {/* Links Section */}
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6 lg:gap-20 text-gray-600">
            <div>
              <h5 className="font-semibold mb-4">Products</h5>
              <ul>
                <li>
                  <a href="#" className="hover:underline">
                    Storytale
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h5 className="font-semibold mb-4">Categories</h5>
              <ul>
                <li>
                  <a href="#" className="hover:underline">
                    Pro Access
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h5 className="font-semibold mb-4">Company</h5>
              <ul>
                <li>
                  <a href="#" className="hover:underline">
                    Become an Author
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h5 className="font-semibold mb-4">Info</h5>
              <ul>
                <li>
                  <a href="#" className="hover:underline">
                    License
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="mt-8 border-t pt-6 flex flex-col md:flex-row justify-between items-center text-center md:text-left text-gray-600">
          {/* Copyright Section */}
          <p>&copy; 2023 CareCrew. All rights reserved.</p>

          {/* Social Media Links */}
          {/* <div className="flex space-x-4 mt-4 md:mt-0">
            <a href="#" className="hover:text-blue-500 transition-colors">
              <FontAwesomeIcon icon={["fab", "twitter"]} className="h-5" />
            </a>
            <a href="#" className="hover:text-pink-500 transition-colors">
              <FontAwesomeIcon icon={["fab", "instagram"]} className="h-5" />
            </a>
            <a href="#" className="hover:text-blue-700 transition-colors">
              <FontAwesomeIcon icon={["fab", "facebook"]} className="h-5" />
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
