export default {
  FONT_SIZE_SMALL: 12,
  FONT_SIZE_MEDIUM: 14,
  FONT_SIZE_LARGE: 16,

  APP_LIGHT_BG_COLOR: "#FAFAFA",
  WHITE: "#FFFFFF",
  GRAY: "#C4C4C4",
  GREEN: "#8ec91a",
  RED: "#F33047",
  APP_DARK_BG_COLOR: "#282828",

  PRIMARY_COLOR: "#00233c",
  // PRIMARY_COLOR: "#18A3AC",

  PRIMARY_COLOR_2: "#16478E",

  PRIMARY_LIGHT_COLOR: "#99a7b1",
  // PRIMARY_LIGHT_COLOR: "#a2dadd",

  PRIMARY_NEUTRAL: "#86d1e61A",

  PRIMARY_NEUTRAL_COLOR: "#F3FAFD",
  PRIMARY_INAPP_COLOR: "#D9F7FC",

  DARK_GRAY: "#5C5C5C",
  LIGHT_GRAY: "#f8f8f8",

  RED_LIGHT: "#F330471A",

  SECONDARY_COLOR: "#fd7e14",
  // SECONDARY_COLOR: "#f6994f",

  TEXT_LIGHT_THEME: "rgba(0, 0, 0, 1)",
  TEXT_DARK_THEME: "rgba(255, 255, 255, 1)",

  TEXT_GREY_LIGHT_THEME: "#EDEDED;",

  TAG_LIGHT_BG_COLOR: "rgba(241, 241, 241, 1)",

  FONT_WEIGHT_LIGHT: 200,
  FONT_WEIGHT_MEDIUM: 600,
  FONT_WEIGHT_HEAVY: 800,

  text_xs: "0.6rem", // you can add the custom size here
  text_base: "0.40rem", // you can add the custom size here
  text_lg: "1.5rem",

  MODAL_BORDER_RADIUS: 20,
};
