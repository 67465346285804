import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import "./fontAwesome";

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<LandingPage />} />
      {/* Add more routes as needed */}
    </Routes>
  </Router>
);

export default App;
