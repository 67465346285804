import React from "react";
import themeStyles from "../styles/theme.styles";

const TestimonialsSection = () => (
  <section className="py-16 px-4 bg-gray-100 text-center" id="testimonials">
    <h2 className="text-2xl font-bold text-gray-800 mb-10">
      What Our Users Say
    </h2>
    <div className="flex flex-wrap justify-center gap-6">
      <div className="bg-white p-6 rounded-lg shadow-md flex flex-col text-left w-full sm:w-1/2 md:w-1/3 max-w-sm">
        <p className="text-gray-600">
          "I have been using ShiftMedix for a year now. Everything is detailed
          and well organized and, of course, damn beautiful."
        </p>
        <p
          className="text-lg font-bold text-primary mt-4"
          style={{ color: themeStyles?.PRIMARY_COLOR }}
        >
          John Doe
        </p>
        <p className="text-sm text-gray-600">Nurse Manager, ABC Hospital</p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-md flex flex-col text-left w-full sm:w-1/2 md:w-1/3 max-w-sm">
        <p className="text-gray-600">
          "ShiftMedix has revolutionized our scheduling and task management.
          It's a must-have tool for any healthcare team."
        </p>
        <p
          className="text-lg font-bold text-primary mt-4"
          style={{ color: themeStyles?.PRIMARY_COLOR }}
        >
          Jane Smith
        </p>
        <p className="text-sm text-gray-600">Head Nurse, XYZ Clinic</p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-md flex flex-col text-left w-full sm:w-1/2 md:w-1/3 max-w-sm">
        <p className="text-gray-600">
          "The efficiency and ease of use are unparalleled. Our team is now more
          coordinated and productive."
        </p>
        <p
          className="text-lg font-bold text-primary mt-4"
          style={{ color: themeStyles?.PRIMARY_COLOR }}
        >
          Michael Brown
        </p>
        <p className="text-sm text-gray-600">HR Manager, 123 Healthcare</p>
      </div>
    </div>
  </section>
);

export default TestimonialsSection;
