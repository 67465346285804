import React, { useState } from "react";
import themeStyles from "../styles/theme.styles";
import Modal from "./SignUpForDemoModal";

const HeroSection = ({ isVisible, onClose, onOpen }) => {
  // const [isModalVisible, setModalVisible] = useState(false);

  // const handleOpenModal = () => setModalVisible(true);
  // const handleCloseModal = () => setModalVisible(false);

  return (
    <section className="container mx-auto flex flex-col lg:flex-row items-center justify-between px-4 sm:px-8 lg:px-16 py-12 lg:py-24 bg-white">
      <div className="w-full lg:w-1/2 text-center lg:text-left">
        <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-800 leading-snug">
          Empower Your Healthcare Team with Seamless{" "}
          <span style={{ color: themeStyles?.PRIMARY_COLOR }}>
            Shift Management
          </span>
        </h1>
        <p className="text-base sm:text-lg text-gray-600 mt-4 leading-relaxed">
          Our intuitive platform empowers healthcare facilities to connect with
          staff, optimize staffing, and improve work-life balance for nurses.
        </p>
        <div className="flex flex-col sm:flex-row gap-4 mt-6 justify-center lg:justify-start">
          <button
            className="bg-primary text-white px-6 py-3 rounded border-2 border-primary font-semibold hover:bg-blue-700 transition inline-flex items-center"
            style={{ backgroundColor: themeStyles?.PRIMARY_COLOR }}
            onClick={onOpen}
          >
            Sign up for an invite
          </button>
          <button
            className="bg-white text-primary px-6 py-3 rounded border-2 border-primary font-semibold hover:bg-gray-200 transition inline-flex items-center"
            style={{
              color: themeStyles?.PRIMARY_COLOR,
              borderColor: themeStyles?.PRIMARY_COLOR,
            }}
          >
            Book A Free Demo
          </button>
        </div>
      </div>
      <div className="w-full lg:w-1/2 flex justify-center items-center mt-8 lg:mt-0">
        <img
          src="/assets/HeroImage.png"
          alt="Hero"
          className="w-full h-auto max-w-xs sm:max-w-sm md:max-w-md object-cover"
        />
      </div>

      {/* Modal for Sign up */}
      <Modal isVisible={isVisible} onClose={onClose}>
        <h2 className="text-lg font-bold mb-4">Sign Up for an Invite</h2>
        <form>
          <div className="mb-4">
            <label htmlFor="name" className="block text-gray-700 font-semibold">
              Name
            </label>
            <input
              type="text"
              id="name"
              className="w-full border rounded px-3 py-2 mt-1"
              placeholder="Enter your name"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 font-semibold"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="w-full border rounded px-3 py-2 mt-1"
              placeholder="Enter your email"
            />
          </div>
          <button
            type="submit"
            className="bg-primary text-white px-6 py-3 rounded font-semibold hover:bg-blue-700 transition"
          >
            Submit
          </button>
        </form>
      </Modal>
    </section>
  );
};

export default HeroSection;
